import React, {useCallback} from "react";
import BuiltForm from "../../../organisms/BuiltForm";
import buildGameNextStepUrl from "@gotombola/react-ds/lib/utils/buildGameNextStepUrl";
import useBuildUrlFor from "@gotombola/react-ds/lib/hooks/useBuildUrlFor";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import useWorkflowStep from "@gotombola/react-ds/lib/hooks/useWorkflowStep";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

const defaultOwnerForm = 'email|phone*';

const defaultValue = { // @todo remove
    email: '', phone: '', lastName: '', firstName: '', age: '', country: '',
    city: '', zipCode: '', birthdate: '', organization: '', id: '', table: '', group: '',
};

export function OwnerBuyGameForm({ game, workflows }: OwnerBuyGameFormProps) {
    const {t} = useTypeTranslation('game', game);
    const urlBuilder = useBuildUrlFor('game', game, false);
    const query = useQueryString();
    const {nextStep} = useWorkflowStep(workflows, 'sale', 'owner', {game});

    const handleSubmit = useCallback((data: any) => {
        data.email ? query.set('o', data.email) : query.set('o', data.phone);
        data.email && data.phone && query.set('op', data.phone);
        data.firstName && query.set('of', data.firstName);
        data.lastName && query.set('ol', data.lastName);
        data.age && query.set('oag', data.age);
        data.country && query.set('oco', data.country);
        data.city && query.set('oci', data.city);
        data.zipCode && query.set('ozc', data.zipCode);
        data.birthdate && query.set('obd', data.birthdate);
        data.organization && query.set('oor', data.organization);
        data.id && query.set('oid', data.id);
        data.table && query.set('ota', data.table);
        data.group && query.set('ogr', data.group);

        const url = buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder);
        url && (location.href = url);
    }, [query, nextStep, game, urlBuilder]);

    return (
        <BuiltForm onSubmit={handleSubmit} dsn={game.customOwnerForm} defaultDsn={defaultOwnerForm} defaultValue={defaultValue} t={t}
                   description={game.buyOwnerMessage} text={t('game_buy_owner_page_subtitle')}
                   mandatoryOptInMessage={game.saleMandatoryOptInMessage} buttonLabel={t('game_buy_owner_payment_btn_label')}
        />
    );
}

export interface OwnerBuyGameFormProps {
    game: any;
    workflows: any;
}

export default OwnerBuyGameForm;
