import React from 'react';
import {Game, Sale} from "@gotombola/sdk";
import SalePaidInfos from "../molecules/sale-infos/SalePaidInfos";
import SalePreorderedInfos from "../molecules/sale-infos/SalePreorderedInfos";
import SalePromisedInfos from "../molecules/sale-infos/SalePromisedInfos";
import SaleCanceledInfos from "../molecules/sale-infos/SaleCanceledInfos";
import SaleCreatedInfos from "../molecules/sale-infos/SaleCreatedInfos";
import FramedBlock from "../molecules/FramedBlock";

export function SaleStatusPanel({game, sale}: SaleStatusPanelProps) {
    return (
        <FramedBlock>
            {('PAID' === sale.status) && <SalePaidInfos game={game} sale={sale} />}
            {('PREORDERED' === sale.status) && <SalePreorderedInfos game={game} sale={sale} />}
            {('PROMISED' === sale.status) && <SalePromisedInfos game={game} sale={sale} />}
            {('CANCELED' === sale.status) && <SaleCanceledInfos game={game} sale={sale} />}
            {('CREATED' === sale.status) && <SaleCreatedInfos game={game} sale={sale} />}
        </FramedBlock>
    );
}

export interface SaleStatusPanelProps {
    game: Game;
    sale: Sale;
}

export default SaleStatusPanel;