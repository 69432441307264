import ChoiceMenu from "@gotombola/react-ds/lib/molecules/ChoiceMenu";
import React from "react";
import useGameBuyBonuses from "../../../hooks/useGameBuyBonuses";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

export function BonusBuyGameForm({ game, workflows }: BonusBuyGameFormProps) {
    const {t} = useTypeTranslation('game', game);
    const items = useGameBuyBonuses(game, { t, workflows });

    return (
        <ChoiceMenu items={items} />
    );
}

export interface BonusBuyGameFormProps {
    game: any;
    workflows: any;
}

export default BonusBuyGameForm;
