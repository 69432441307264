import React from 'react';
import {Game, Sale} from "@gotombola/sdk";
import formatRawAmount from "@gotombola/amount/lib/utils/formatRawAmount";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";
import SaleButtons from "../SaleButtons";

export function SalePromisedInfos({game, sale}: SalePromisedInfosProps) {
    const {t} = useTypeTranslation('game', game);

    return (
        <>
            <div className={'flex sm:w-2/3 flex-col h-full space-y-4'}>
                <div className={"font-go-text text-2xl text-green-600"}>{t(sale.paymentAmount > 0 ? 'sale_public_page_promised_thank' : 'sale_public_page_promised_thank_free', {price: formatRawAmount(sale.paymentAmount, sale.paymentCurrency)})}</div>
                <div className={"font-go-text text-xl text-green-600"}>{t('sale_public_page_promised_thank_2')}</div>
                <div className={"font-go-text text-xl mt-4"}>{t('sale_public_page_spam_advice')}</div>
            </div>
            <div className={'flex flex-col w-2/3 sm:w-1/3'}>
                <SaleButtons game={game} sale={sale} />
            </div>
        </>
    );
}

export interface SalePromisedInfosProps {
    game: Game & Record<string, any>;
    sale: Sale & Record<string, any>;
}

export default SalePromisedInfos;