import React from 'react';
import {Game, Sale} from "@gotombola/sdk";
import formatRawAmount from "@gotombola/amount/lib/utils/formatRawAmount";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";
import SaleButtons from "../SaleButtons";

export function SaleCanceledInfos({game, sale}: SaleCanceledInfosProps) {
    const {t} = useTypeTranslation('game', game);

    return (
        <>
            <div className={'flex sm:w-2/3 flex-col h-full space-y-4'}>
                <div className={"font-go-text text-go-text text-2xl text-purple-600"}>{t('sale_public_page_finish_participation', {price: formatRawAmount(sale.paymentAmount, sale.paymentCurrency)})}</div>
                <div className={"font-go-text text-go-text text-xl text-purple-600"}>{t('sale_public_page_finish_participation_2')}</div>
            </div>
            <div className={'flex flex-col w-2/3 sm:w-1/3'}>
                <SaleButtons game={game} sale={sale} contactus />
            </div>
        </>
    );
}

export interface SaleCanceledInfosProps {
    game: Game & Record<string, any>;
    sale: Sale & Record<string, any>;
}

export default SaleCanceledInfos;